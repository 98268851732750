import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth"
import { getDatabase } from "firebase/database";
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"

const firebaseConfig = {
    apiKey: "AIzaSyAxSqSFlKb9khjd2Und2SOoPVH0sJy1txc",
    authDomain: "esp32-d1630.firebaseapp.com",
    databaseURL: "https://esp32-d1630-default-rtdb.firebaseio.com",
    projectId: "esp32-d1630",
    storageBucket: "esp32-d1630.appspot.com",
    messagingSenderId: "518861948033",
    appId: "1:518861948033:web:d9601fd0937bb9bd26f980",
    measurementId: "G-F07YJBKSP2"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

export const auth = getAuth(app)

export const db = getDatabase(app)

export const storage = getStorage(app)