import { onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { db } from '../firebase';
import { GiCampingTent } from 'react-icons/gi';
import { doc } from 'firebase/firestore';
import Loading from '../component/Loading';

function Admin() {
    type DataItem = {
        name: string,
        voltage: number,
        enable: boolean
    }
    const [data, setData] = useState<DataItem[]>([]);
    useEffect(() => {
        document.title = "營區管理系統"
        onValue(ref(db), (snap) => {
            const snapshotData = snap.val();
            console.log(snapshotData)
            if (snapshotData !== null) {
                setData(Object.values(snapshotData));
            }
        });
    }, [])
    if (!data || data.length === 0) return <div className='fixed inset-0 flex items-center justify-center'><Loading /></div>
    return (
        <div>
            <GiCampingTent className='fixed bottom-0 right-0 -z-10 opacity-5 text-blue-400 text-[256px]' />
            <div className="sticky flex top-0 w-full p-4 mb-4 border-b bg-white z-10 items-center justify-between">
                <h2 className='text-xl font-bold'>營區管理系統</h2>
            </div>
            <div className="px-4">
                <h2 className='text-2xl font-bold text-left'>
                    總再生功率：
                    <span className='text-blue-400'>{(data[0].voltage + data[1].voltage + data[2].voltage).toFixed(2)}w</span>
                </h2>
            </div>
            <div className="max-w-[768px] mx-auto">
                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 items-center gap-4 m-4 mb-20">
                    {data?.map((item, index) => (
                        <div className={`p-4 rounded-2xl border bg-white min-h-64 flex flex-col ${item.enable ? 'block' : 'hidden'}`} key={index}>
                            <div className="flex text-left">
                                <p className='text-xl font-bold'>{item.name}</p>
                            </div>
                            <div className="grow relative flex items-center justify-center">
                                <p className='text-[84px] text-blue-400'>
                                    {item.voltage.toFixed(2)}
                                </p>
                                <p className='absolute bottom-0 right-0 text-blue-400'>w</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Admin