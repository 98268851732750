import React, { useContext, useEffect, useState } from 'react';
import { db } from './firebase';
import { onValue, ref } from 'firebase/database';
import { GiCampingTent } from "react-icons/gi";
import { Route, Routes } from 'react-router-dom';
import Member from './page/Member';
import Admin from './page/Admin';
function App() {
  // console.log(data);
  // if (!voltage || !voltage.name || !voltage.voltage) return <div>Loading...</div>;
  // if (voltage?.voltage == null || voltage.voltage == undefined) return <div></div>;
  return (
    <div>
      <Routes>
        <Route path='/' element={<Member />} />
        <Route path='/admin' element={<Admin />} />
      </Routes>
    </div>
  );
}

export default App;
