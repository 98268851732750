import { onValue, ref } from 'firebase/database';
import React, { useEffect, useState } from 'react'
import { db } from '../firebase';
import { GiCampingTent } from 'react-icons/gi';
import Loading from '../component/Loading';

function Member() {
    type DataItem = {
        name: string,
        voltage: number,
        enable: boolean
    }
    const cost: number = 1.63;
    const kWh: number = 300;
    const [data, setData] = useState<DataItem[]>([]);
    const [show, setShow] = useState<boolean>(false);
    useEffect(() => {
        document.title = "營區計價系統"
        onValue(ref(db), (snap) => {
            const snapshotData = snap.val();
            console.log(snapshotData)
            if (snapshotData !== null) {
                setData(Object.values(snapshotData));
            }
        });
    }, [])
    if (!data || data.length === 0) return <div className='fixed inset-0 flex items-center justify-center'><Loading /></div>
    return (
        <div>
            <div className={`z-50 inset-0 ${show ? "fixed" : "hidden"}`}>
                <div className="absolute max-w-[768px] top-6 w-full bg-white px-4 p-3 left-1/2 -translate-x-1/2 rounded-xl">
                    <p className='text-2xl font-bold py-2 border-b'>計價系統</p>
                    <div className="py-2">
                        <table className='table-fixed w-full'>
                            <thead>
                                <tr>
                                    <th>基本入住費用</th>
                                    <th className='text-blue-400 py-2'>+</th>
                                    <th>(目前消耗度電</th>
                                    <th className='text-blue-400 py-2'>X</th>
                                    <th>一度電)</th>
                                    <th className='text-blue-400 py-2'>=</th>
                                    <th>總花費</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className='text-center text-blue-400 py-2'>500</td>
                                    <td className='text-center'></td>
                                    <td className='text-center text-blue-400 py-2'>{kWh}</td>
                                    <td className='text-center'></td>
                                    <td className='text-center text-blue-400 py-2'>{cost}</td>
                                    <td className='text-center'></td>
                                    <td className='text-center text-blue-400 py-2'>{500 + (kWh * cost)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                    <div className="flex justify-end gap-2 py-2 border-t">
                        <button onClick={() => setShow(false)} className='p-2 px-4 rounded-lg bg-gray-200'>返回</button>
                        <button onClick={() => setShow(false)} className='p-2 px-4 rounded-lg text-white bg-blue-400'>結算</button>
                    </div>
                </div>
                <div onClick={() => setShow(false)} className="bg-black/30 w-full h-full"></div>
            </div>
            <GiCampingTent className='fixed bottom-0 right-0 -z-10 opacity-5 text-blue-400 text-[256px]' />
            <div className="sticky flex top-0 w-full p-4 mb-4 border-b bg-white z-10 items-center justify-between">
                <h2 className='text-xl font-bold'>營區計價系統</h2>
                <button onClick={() => { setShow(true) }} className='rounded-xl bg-blue-500 p-2 px-4 text-white'>開始計價</button>
            </div>
            <div className="px-4">
                <h2 className='text-2xl font-bold text-left'>
                    總再生功率：
                    <span className='text-blue-400'>{(data[0].voltage + data[1].voltage + data[2].voltage).toFixed(2)}w</span>
                </h2>
            </div>
            <div className="max-w-[768px] mx-auto">
                <div className="rounded-xl bg-white p-2">
                    <table className="table-fixed w-full">
                        <thead>
                            <tr>
                                <th>基本入住費用</th>
                                <th className='text-blue-400 py-2'>+</th>
                                <th>(目前消耗度電</th>
                                <th className='text-blue-400 py-2'>X</th>
                                <th>一度電)</th>
                                <th className='text-blue-400 py-2'>=</th>
                                <th>總花費</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td className='text-center text-blue-400 py-2 text-4xl'>500</td>
                                <td className='text-center'></td>
                                <td className='text-center text-blue-400 py-2 text-4xl'>{kWh}</td>
                                <td className='text-center'></td>
                                <td className='text-center text-blue-400 py-2 text-4xl'>{cost}</td>
                                <td className='text-center'></td>
                                <td className='text-center text-blue-400 py-2 text-4xl'>{500 + (kWh * cost)}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
            {/* <div className="fixed bottom-0 p-2 w-full sm:w-auto">
                <div className="flex items-center justify-between text-left w-full gap-2 bg-white p-3 px-4 rounded-xl border">
                    <p className='font-bold'>基本入住費用: <span className='text-blue-400'>500</span> 元</p>
                    <p className='font-bold text-green-400'>+</p>
                    <p className='font-bold'>目前消耗度電: <span className='text-blue-400'>{kWh}</span> 度</p>
                    <p className='font-bold text-green-400'>X</p>
                    <p className='font-bold'>一度電: <span className='text-blue-400'>{cost}</span> 元</p>
                    <p className='font-bold text-green-400'>=</p>
                    <p className='font-bold'>總花費: <span className='text-blue-400'>{500 + (kWh * cost)}</span> 元</p>
                </div>
            </div> */}
        </div>
    )
}

export default Member